import React from 'react';
import Footer from './Footer';
import '../styles/LegalMentions.css';

function LegalMentionsPage() {
    return (
        <div className="legal-mentions-main-container">
            <div className="legal-mentions-content">
                <h2>Mentions Légales</h2>
                <p><strong>Éditeur du Site :</strong></p>
                <p>Le site Recouvro est édité par DIGHITEC INNOVATIONS, une Société à Responsabilité Limitée (SARL) au capital social de 100 000 FCFA, immatriculée au REGISTRE
                DU COMMERCE ET DU CREDIT MOBILIER sous le numéro RB/ABC/23 B, et domiciliée à Abomey-Calavi.</p> IFU : 3202322510966
                <br></br>
                <br></br>
                <p><strong>Hébergement :</strong></p>
                <p>Le site Recouvro est hébergé par DIGITAL OCEAN.</p>
                <p><strong>Contact :</strong></p>
                <p>Pour toute question, vous pouvez contacter l'éditeur à l'adresse suivante : Joy@recouvro.com.</p>
                <p><strong>Propriété Intellectuelle :</strong></p>
                <p>Tous les contenus présents sur le site Recouvro, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de DIGHITEC INNOVATIONS, à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.</p>
                <p><strong>Conditions Générales d'Utilisation :</strong></p>
                <p>L'accès au site Recouvro implique l'acceptation pleine et entière des conditions générales d'utilisation décrites dans les présentes mentions légales. Ces conditions sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de manière régulière.</p>
                <p><strong>Protection des Données Personnelles :</strong></p>
                <p>DIGHITEC INNOVATIONS s'engage à respecter la confidentialité des données personnelles collectées sur le site Recouvro, conformément à la législation en vigueur. Pour toute information relative à la collecte et au traitement des données personnelles, veuillez consulter notre <a href="#privacy">Politique de Confidentialité</a>.</p>
            </div>
            <Footer />
        </div>
    );
}

export default LegalMentionsPage;
