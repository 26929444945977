import React, { useState } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import '../styles/RegistrationPage.css'; // Add CSS file for the registration page
import recouvroLogo from '../assets/recouvro_logo.svg'; // Import the logo
import Footer from './Footer'; 
function RegistrationPage() {
    const [formData, setFormData] = useState({
        user_name: '',
        agency_name: '',
        number_of_account_desired: '',
        email: '',
        heard_of_from: '',
        phone_number: '',
        registran_role: '',
        agency_or_individual: '',
        message: ''
    });
    const [modalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('/waitinglist/', formData);
            setModalVisible(true);
            setFormData({
                user_name: '',
                agency_name: '',
                number_of_account_desired: '',
                email: '',
                heard_of_from: '',
                phone_number: '',
                registran_role: '',
                agency_or_individual: '',
                message: ''
            });
        } catch (error) {
            console.error("There was an error submitting the form!", error);
        }
    };

    return (
        <div className="registration-main-container">
            <div className="registration-container">
                <div className="registration-logo">
                <a href="/"> {/* Add link to homepage */}
                        <img src={recouvroLogo} alt="Recouvro Logo" />
                </a>
                </div>
                <h2>Inscription</h2>
                <form onSubmit={handleSubmit} className="registration-form">
                    <div className="form-group">
                        <label htmlFor="user_name">Votre nom</label>
                        <input
                            type="text"
                            id="user_name"
                            name="user_name"
                            value={formData.user_name}
                            onChange={handleChange}
                            maxLength={30}
                        />
                    </div>
                    <div className="form-group">
                    <label htmlFor="agency_or_individual">Agence ou Propriétaire Individuel</label>
                        <select
                            id="agency_or_individual"
                            name="agency_or_individual"
                            value={formData.agency_or_individual}
                            onChange={handleChange}
                        >
                            <option value="">Sélectionner</option>
                            <option value="Agence">Agence</option>
                            <option value="Individuel">Individuel</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="agency_name">Nom de l'agence</label>
                        <input
                            type="text"
                            id="agency_name"
                            name="agency_name"
                            value={formData.agency_name}
                            onChange={handleChange}
                            maxLength={30}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="registran_role">Votre Rôle</label>
                        <input
                            type="text"
                            id="registran_role"
                            name="registran_role"
                            value={formData.registran_role}
                            onChange={handleChange}
                            maxLength={30}
                            placeholder='exemple : Gérant agence, Agent immo'
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="number_of_account_desired">Nombre de comptes souhaités</label>
                        <input
                            type="number"
                            id="number_of_account_desired"
                            name="number_of_account_desired"
                            value={formData.number_of_account_desired}
                            onChange={handleChange}
                            maxLength={30}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            maxLength={30}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="heard_of_from">Comment avez-vous entendu parler de nous?</label>
                        <select
                            id="heard_of_from"
                            name="heard_of_from"
                            value={formData.heard_of_from}
                            onChange={handleChange}
                        >
                            <option value="">Sélectionner</option>
                            <option value="Internet">Internet</option>
                            <option value="Bouche à oreille">Bouche à oreille</option>
                            <option value="Publicité">Publicité</option>
                            <option value="Autre">Autre</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone_number">Numéro de téléphone</label>
                        <input
                            type="text"
                            id="phone_number"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            maxLength={30}
                        />
                    </div>


                    <div className="form-group">
                        <label htmlFor="message">Message (optionnel)</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            maxLength={300}
                        />
                    </div>
                    <button type="submit" className="registration-button">Soumettre</button>
                </form>
                {modalVisible && (
                    <div className="modal">
                         <p>Merci pour votre inscription ! Nous vous contacterons dans les 48 heures.</p>
                        <button 
                            onClick={() => {
                                navigate('/'); // Redirect to homepage
                                setModalVisible(false);
                            }}
                        >
                            Retour à la page d'accueil
                        </button>
                    </div>
                )}
            </div>
            <Footer /> {/* Add Footer component */}
        </div>
    );
}

export default RegistrationPage;
