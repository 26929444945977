import React from 'react';
import '../styles/Hero.css';

const Hero = () => {
    return (
      <section className="hero">
        <div className="hero-content">
          <h1>Simplifiez le recouvrement de loyers et la délivrance de quittances</h1>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <a href="/register" className="hero-button">Commencez avec nous</a>
        </div>
      </section>
    );
  };
  
  export default Hero;