import React from "react";
import "../styles/LoginPage.css";
import recouvroLogo from "../assets/recouvro_logo.svg";

const LoginPage = () => {
    return (
        <div className="login-main-container">
            <div className="login-container">
                <div className="login-logo">
                    <img src={recouvroLogo} alt="Recouvro Logo" />
                </div>
                <h2 className="h2">Connexion</h2>
                <form className="login-form">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input type="password" id="password" name="password" required />
                    </div>
                    <button type="submit" className="login-button">
                        Se connecter
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
