import React from 'react';
import '../styles/Pricing.css';

const Pricing = () => {
    return (
        <section className="pricing-section">

            <h2 className="pricing-title">Nos Offres pour les Agences</h2>

            <div className="pricing-cards">
                <div className="pricing-card basic-plan">
                    <h3 className="plan-title">Plan de base</h3>
                    <p className="plan-price">7500 FCFA<span>/mois</span></p>
                    <ul className="plan-features">
                        <li>3 utilisateurs max</li>
                        <li>30 logements max</li>
                        <li>150 locataires max</li>
                        <li>Support jours/heures ouvrés</li>
                    </ul>
                    <button className="plan-button-basic" onClick={() => window.location.href = '/register' }>Adoptez Recouvro</button>
                </div>
                <div className="pricing-card pro-plan">
                    <div className="recommended">Recommandé</div>
                    <h3 className="plan-title">Plan Pro</h3>
                    <p className="plan-price">15 000 FCFA<span>/mois</span></p>
                    <ul className="plan-features">
                        <li>jusqu'à 10 utilisateurs</li>
                        <li>Logements illimités</li>
                        <li>Locataires illimités</li>
                        <li>Stockage de quittances illimitées</li>
                        <li>Tableaux de bord illimités</li>
                        <li>Support premium</li>
                    </ul>
                    <button className="plan-button-pro" onClick={() => window.location.href = '/register'} >Adoptez Recouvro</button>
                </div>
            </div>

            {/* CTA for Independent Landlords with Separate Divs */}
            <div className="cta-independent-landlord">
                <div className="cta-text">
                    Vous êtes un propriétaire individuel ?
                </div>
                <a href="/register" className="hero-button-down">
                    Contactez-nous
                </a>
            </div>

        </section>
    );
};

export default Pricing;
