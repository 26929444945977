import React from 'react';
import '../styles/Footer.css';

function Footer() {
    return (
      <footer>
        <div className="footer-links">
          <a href="#features">Fonctionnalités</a>
          <a href="#pricing">Tarification</a>
          <a href="/legal-mentions">Mentions légales</a>
          <a href="#privacy">Politique de confidentialité</a>
        </div>
        <div className="social-media-icons">
          <a href="#"><i className="fab fa-facebook-f"></i></a>
          <a href="#"><i className="fab fa-twitter"></i></a>
          <a href="#"><i className="fab fa-linkedin-in"></i></a>
        </div>
        <div className="contact-info">
          <p>Contact : +229 54763317</p>
          <p>Email : Hello@recouvro.com</p>
        </div>
      </footer>
    );
  }
  
  export default Footer;