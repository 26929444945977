import React from 'react';
import '../styles/Features.css';

const Features = () => {
  return (
    <section className="features">
      <div className="feature-container">
        <div className="feature-box with-recouvro">
          <h2>Avec Recouvro</h2>
          <ul>
            <li>✓ Recouvrement automatisé</li>
            <li>✓ Quittance instantanée</li>
            <li>✓ Gestion des locataires</li>
            <li>✓ Rapports de paiements en temps réèl</li>
          </ul>
        </div>
        <div className="feature-box without-recouvro">
          <h2>Sans Recouvro</h2>
          <ul>
            <li>✗ Suivi en temps réel compliqué</li>
            <li>✗ Perte de temps chaque mois </li>
            <li>✗ Dépenses accrues</li>
            <li>✗ Gestion manuelle fastidieuse</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Features;
