import React from "react";
import "../styles/Header.css";
import recouvroLogo from "../assets/recouvro_logo.svg"; // Assurez-vous que le logo est placé dans ce chemin
import { Link } from "react-router-dom";

function Header() {
    return (
        <header>
            <div className="container">
                <a href="/" className="logo-link">
                    <img src={recouvroLogo} alt="Recouvro Logo" className="logo" />
                </a>
                <nav>
                    <ul>
                        <li>
                            <a href="#features">Fonctionnalités</a>
                        </li>
                        <li>
                            <a href="#how-it-works">Comment ça marche</a>
                        </li>
                        <li>
                            <a href="#testimonials">Témoignages</a>
                        </li>
                        <li>
                            <a href="#pricing">Tarifs</a>
                        </li>
                        <li>
                            <Link to="/login" className="login-btn">
                                Connexion
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
