import React from 'react';
import '../styles/Testimonials.css';

const testimonials = [
  {
    text: "Recouvro a transformé notre façon de gérer les loyers. L'application est facile à utiliser et nos locataires apprécient la simplicité de paiement.",
    author: "Jonathan",
  },
  {
    text: "Avec Recouvro, nous avons réduit le temps passé sur la gestion des quittances. C'est une véritable révolution pour notre agence.",
    author: "Gigonou",
  },
];

const Testimonials = () => {
  return (
    <div className="testimonials-section">
      <h2>Ce que disent nos clients</h2>
      <div className="testimonials">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <p>{testimonial.text}</p>
            <h4>{testimonial.author}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
