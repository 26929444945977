import React, { useState } from 'react';
import '../styles/HowItWorks.css';
import step1Image from '../assets/images/step1.gif'; // Assurez-vous que le chemin est correct
import step2Image from '../assets/images/step2.png';
import step3Image from '../assets/images/step3.gif';
import step4Image from '../assets/images/step4.png';
import step5Image from '../assets/images/step5.png';

const steps = [
  {
    title: '1. Création des propriétés et des locataires',
    content: 'Créez vos maisons et locataires en quelques clics',
    image: step1Image,
  },
  {
    title: '2. Génération d\'un lien de paiement',
    content: 'Les locataires reçoivent un identifiant unique et un lien de paiement pour régler leur loyer en ligne.',
    image: step2Image,
  },
  {
    title: '3. Les locataires effectuent leur paiements en ligne par MoMo',
    content: 'Chaque locataire effectue son paiement en en ligne sur la page dédié de l\'agence ou du propriétaire.',
    image: step3Image,
  },
  {
    title: '4. Les quittances sont générées et envoyées automatiquement',
    content: 'Les quittances sont générées et envoyées automatiquement après paiement.',
    image: step4Image,
  },
  {
    title: '5. Suivi des paiements',
    content: 'Suivez les paiements via un tableau de bord clair et puissant.',
    image: step5Image,
  },
];

const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepClick = (index) => {
    setActiveStep(index);
  };

  return (
    <div className="how-it-works">
      <h2>Comment ça fonctionne</h2>
      <div className="how-it-works-container">
        <div className="how-it-works-steps">
          {steps.map((step, index) => (
            <div key={index} className="how-it-works-step">
              <div
                className={`step-header ${activeStep === index ? 'active' : ''}`}
                onClick={() => handleStepClick(index)}
              >
                {step.title}
              </div>
              {activeStep === index && (
                <div className="step-content">
                  <p>{step.content}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="how-it-works-image">
          <img src={steps[activeStep].image} alt="Step" />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
