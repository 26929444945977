import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from 'react';
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "./components/Testimonials";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer";
import LoginPage from "./components/LoginPage";
import RegistrationPage from "./components/RegistrationPage"; 
import LegalMentionsPage from './components/LegalMentions';
import "./App.css";

function Home() {
    return (
        <>
            <Header />
            <Hero />
            <section id="features">
                <Features />
            </section>
            <section id="how-it-works">
                <HowItWorks />
            </section>
            <section id="testimonials">
                <Testimonials />
            </section>
            <section id="pricing">
                <Pricing />
            </section>
            <Footer />
        </>
    );
}

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegistrationPage />} />
                <Route path="/legal-mentions" element={<LegalMentionsPage/>} />
            </Routes>
        </Router>
    );
}

export default App;
